import React, { useState, useEffect } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import IconButton from "@vapor/react-material/IconButton";
import Button from "@vapor/react-material/Button";
import Alert from "@vapor/react-material/Alert";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

import {
  LicenseInfo,
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Icons
import {
  ArrowForward as DetailIcon,
  Add as AddIcon,
  DeleteOutlineOutlined as DeleteIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";
import { NoData } from "../../components/NoData";
import { ModalDelete } from "../../components/ModalDelete";

import {
  bsaAzienda,
  pathAziendaHome,
  pathAziendaAdd,
  pathHome,
} from "../../businessLogic/constants";
import { setLocalStorageSelectedAzienda } from "../../businessLogic/bl";
import {
  userPermissionModel,
  baseAppSettingModel,
} from "../../businessLogic/models";
import {
  query_aziende_byTenant,
  query_fascicolo_byAzienda_count,
  query_autoValutazione_byAzienda_count,
} from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
  mutation_delete_azienda,
  mutation_get_secret_byname,
  mutation_read_metering_consumption,
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";
import postal from "postal";

export const AziendaHome = ({ basePath }) => {
  const navigate = useNavigate();
  if (
    sharedDatas.getPropertyByName("userId") === "" ||
    sharedDatas.getPropertyByName("tenantId") === "" ||
    sharedDatas.getPropertyByName("aziendaId") === 0
  ) {
    navigate(pathHome);
  }

  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const tenant = sharedDatas.getPropertyByName("tenantModel");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  //Query
  const apiAziendeByTenant = useQuery(
    query_aziende_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiCountFascByAzienda = useMutation(query_fascicolo_byAzienda_count, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiCountAutoByAzienda = useMutation(
    query_autoValutazione_byAzienda_count,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeleteAzienda = useMutation(mutation_delete_azienda, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiReadMeteringConsumption = useMutation(
    mutation_read_metering_consumption,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [isUserOwner, setIsUserOwner] = useState(
    sharedDatas.getPropertyByName("userModel") !== null
      ? sharedDatas.getPropertyByName("userModel").type === "OWNER"
      : false
  );

  //const [userPermissions, setUserPermissions] = useState(new userPermissionModel(0, aziendaId, bsaAzienda, false, false, false, false)); //Contiene i permessi utente
  const [aziendeList, setAziendeList] = useState([]); //Contiene le aziende

  //DataGrid
  const [dataItems, setDataItems] = useState([]); //Contiene i records
  const [dataColumns, setDataColumns] = useState([]); //Contiene le columns
  const [dataRows, setDataRows] = useState([]); //Contiene le rows

  const [showDeleteModal, setShowDeleteModal] = useState(false); //Modal per la conferma di eliminazione di un record
  const [idToPerform, setIdToPerform] = useState(0); //Contiene l'id del record da eliminare

  //const tsApiAntiRic = useGet("aml:ts-anti-ric:api://", { lazy: true });
  // const tsApiMeteringPackage = useGet("aml:metering-package:api://", { lazy: true });
  // const tsApiMeteringConsumption = useGet("aml:metering-consumption:api://", { lazy: true });
  const [showExtraSoglia, setShowExtraSoglia] = useState(false);
  const [usedMessage, setUsedMessage] = useState("");

  useEffect(() => {
    loadDatas();
  }, [isDataLoaded]);
  async function loadDatas() {
    try {
      LicenseInfo.setLicenseKey(
        "81c5b74554e34c1f397e9cedbf341f5dTz05Mzc1MyxFPTE3NTE3OTM2MzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
      );

      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded && loggedUserId !== "" && tenantId !== "") {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathAziendaHome,
            entityId: "",
            entityName: "",
          });

          /**************************************/
          //Recupero i consumi del metering
          let packageId = 0;
          let availableSlots = 0;
          let usedSlots = 0;
          let remainingSlots = 0;

          const addConsumptionResp = await apiReadMeteringConsumption.fetch({
            tenantId: tenantId,
          });
          packageId =
            addConsumptionResp.data.data.readMeteringConsumption.packageId;
          availableSlots =
            addConsumptionResp.data.data.readMeteringConsumption.availableSlots;
          usedSlots =
            addConsumptionResp.data.data.readMeteringConsumption.usedSlots;
          remainingSlots =
            addConsumptionResp.data.data.readMeteringConsumption.remainingSlots;

          setShowExtraSoglia(remainingSlots <= 0);
          setUsedMessage("Utilizzati " + usedSlots + " su " + availableSlots);

          /**************************************/
          //Carico le aziende
          setDataColumns([
            { field: "id", headerName: "Id", flex: 0.2, type: "number" },
            {
              field: "cf",
              headerName: "Codice Fiscale",
              flex: 0.2,
              editable: false,
            },
            { field: "pIva", headerName: "P. Iva", flex: 0.2, editable: false },
            {
              field: "denominazione",
              headerName: "Denominazione",
              flex: 1,
              editable: false,
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.2,
              filterable: false,
              renderCell: (params) => renderDetailsButton(params.row),
            },
          ]);

          const aziendeResponse = await apiAziendeByTenant.fetch({
            tenantId: tenantId,
          });
          const arrItems = [];

          for (let i = 0; i < aziendeResponse.data.data.azienda.length; i++) {
            const item = aziendeResponse.data.data.azienda[i];
            const itemModel = {
              id: item.Id,
              cf: item.Cf,
              pIva: item.PIva,
              denominazione: item.Denominazione,
            };
            arrItems.push(itemModel);
          }

          setAziendeList(arrItems);
          setDataItems(arrItems);
          setDataRows(arrItems);
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AziendaHome",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //Add New Button
  const handleAddNew = () => {
    setLocalStorageSelectedAzienda(0);
    navigate(pathAziendaAdd);
  };

  //DataGrid
  //Grid Button Detail
  const renderDetailsButton = (item) => {
    return (
      <Stack direction="row" spacing={0}>
        {isUserOwner &&
        (tenant.identifier !== item.cf || tenant.vatNumber !== item.pIva) ? (
          <IconButton
            color="error"
            onClick={(e) => handleGridBtnDeleteClick(e, item)}
          >
            <DeleteIcon />
          </IconButton>
        ) : null}
        {isUserOwner ? (
          <IconButton
            color="primary"
            onClick={(e) => handleGridBtnDetailClick(e, item)}
          >
            <DetailIcon />
          </IconButton>
        ) : null}
      </Stack>
    );
  };
  const handleGridBtnDetailClick = (event, item) => {
    event.stopPropagation();
    setLocalStorageSelectedAzienda(item.id);
    navigate("/azienda/manage");
  };
  const handleGridBtnDeleteClick = (event, item) => {
    event.stopPropagation();
    setIdToPerform(item.id);
    setShowDeleteModal(true);
  };

  //Modal Delete
  const handleModalDeleteClose = () => {
    setIdToPerform(0);
    setShowDeleteModal(false);
  };
  const handleModalDeleteOkClick = async () => {
    try {
      if (idToPerform > 0) {
        setBackDropOpen(true);

        const countFascicoli = await apiCountFascByAzienda.fetch({
          tenantId: tenantId,
          aziendaId: idToPerform,
        });
        const countAutoval = await apiCountAutoByAzienda.fetch({
          tenantId: tenantId,
          aziendaId: idToPerform,
        });

        if (
          countFascicoli.data.data.fascicolo_aggregate.aggregate.count <= 0 &&
          countAutoval.data.data.autovalutazione_aggregate.aggregate.count <= 0
        ) {
          await apiDeleteAzienda
            .fetch({
              tenantId: tenantId,
              id: parseInt(idToPerform),
            })
            .then((response) => {
              if (response.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                //Rimuovo l'azienda dall'elenco
                const aziende = Object.assign([], aziendeList);
                const newArr = aziende.filter(
                  (item) => item.id !== idToPerform
                );

                sharedDatas.setPropertyValue("aziendaModelList", newArr);

                //Invio una notifica tramite pub/sub interno
                postal.publish({
                  channel: "azienda",
                  topic: "azienda.update",
                  data: {
                    aziende: newArr,
                  },
                });

                //Se ho eliminato l'azienda attualmente selezionata, switcho alla prima disponibile
                if (
                  parseInt(sharedDatas.getPropertyByName("aziendaId")) ===
                  idToPerform
                ) {
                  sharedDatas.setPropertyValue(
                    "aziendaId",
                    parseInt(newArr[0].id)
                  );
                }

                //Aggiungo la Audit per tracciare l'azione
                // const itemById = JSON.stringify(dataItems.filter(item => item.id === parseInt(idToPerform))[0]);
                // apiAddAudit.fetch({ tenantId: tenantId, aziendaId: aziendaId, area: bsaAzienda, operation: "Delete", entityType: "azienda", entityId: idToPerform.toString(), oldValues: itemById, newValues: "" });

                //Resetto le variabili
                setIdToPerform(0);
                setShowDeleteModal(false);
                setIsDataLoaded(false);
                //loadDatas();
                enqueueSnackbar(t("message.success"), { variant: "success" });
              }
            })
            .catch((e) => {
              apiAddLog.fetch({
                tenantId: sharedDatas.getPropertyByName("tenantId"),
                aziendaId: sharedDatas.getPropertyByName("aziendaId"),
                level: "ERROR",
                message: e.message,
                stack: e.stack,
                area: "FascicoloHome",
                method: "handleModalDeleteOkClick/apiDeleteAzienda.fetch",
                version: sharedDatas.getPropertyByName("version"),
              });
            });
        } else {
          setIdToPerform(0);
          setShowDeleteModal(false);
          enqueueSnackbar(
            "Non è possibile eliminare questo Soggetto in quanto ci sono dati collegati ad esso",
            { variant: "warning" }
          );
        }

        setBackDropOpen(false);
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloHome",
        method: "handleModalDeleteOkClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };

  return (
    <VaporPage
      title={t("nav.aziende")}
      headerRight={
        <Button
          variant="text"
          size="medium"
          endIcon={<AddIcon />}
          color="primary"
          onClick={handleAddNew}
          disabled={!isUserOwner}
          sx={{ mr: 2 }}
        >
          {t("actions.nuovo")}
        </Button>
      }
      headerLeft={
        <ExtendedTypography variant="subtitle2" sx={{ ml: 3 }}>
          {usedMessage}
        </ExtendedTypography>
      }
    >
      <Loading open={backDropOpen} />

      {isDataLoaded && !isUserOwner ? <NotAuth /> : null}

      {showDeleteModal ? (
        <ModalDelete
          show={showDeleteModal}
          onClose={handleModalDeleteClose}
          onOk={handleModalDeleteOkClick}
        ></ModalDelete>
      ) : null}

      {isDataLoaded && isUserOwner ? (
        <VaporPage.Section>
          <Grid container>
            {showExtraSoglia ? (
              <Grid item xs={12}>
                <Alert variant="outlined" severity="warning">
                  {t("azienda.aziendaHome.alert")}
                </Alert>
              </Grid>
            ) : null}
            <Grid item xs={12} sx={{ width: "100%", mt: 3 }}>
              {dataRows.length > 0 ? (
                // <TableData tableSize="medium" columns={dataColumns} rows={dataRows} customRowItems={null} actions={actionsRowItems} onRowClick={(e, item) => handleGridBtnDetailClick(e, item)}></TableData>
                <DataGridPro
                  rows={dataRows}
                  columns={dataColumns}
                  autoHeight={true}
                  slots={{ toolbar: GridToolbar }}
                  pageSizeOptions={[25, 50, 100]}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  pagination
                  disableRowSelectionOnClick
                  onRowClick={(item, e) =>
                    handleGridBtnDetailClick(e, item.row)
                  }
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  slotProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                  }}
                />
              ) : (
                <NoData />
              )}
            </Grid>
          </Grid>
        </VaporPage.Section>
      ) : null}
    </VaporPage>
  );
};
