import React, { useState, useEffect } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporTag from "@vapor/react-custom/VaporTag";

//Vapor Components
import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";
import IconButton from "@vapor/react-material/IconButton";
import Grid from "@vapor/react-material/Grid";

//Icons
import {
  Add as AddIcon,
  ArrowForward as DetailIcon,
  DeleteOutlineOutlined as DeleteIcon,
} from "@mui/icons-material";

//Custom styles
import {
  chipGreen,
  chipYellow,
  chipOrange,
  chipRed,
} from "../../businessLogic/styles";

import {
  LicenseInfo,
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";
import { NoData } from "../../components/NoData";
import { ModalDelete } from "../../components/ModalDelete";

//Constants, Api and Data Models
import {
  bsaAutovalutazione,
  pathAutoValutazioneHome,
} from "../../businessLogic/constants";
import { setLocalStorageSelectedAutovalutazione } from "../../businessLogic/bl";
import { pathAutoValutazioneAdd } from "../../businessLogic/constants";
import {
  userPermissionModel,
  autoValutazioneModel,
} from "../../businessLogic/models";
import {
  query_autoValutazione_byTenant,
  query_user_byId,
} from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_audit,
  mutation_delete_autovalutazione,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
} from "../../businessLogic/mutation";

export const AutovalutazioneHome = ({ basePath }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //Query
  //const apiTenantById = useQuery(query_tenant_byId, {}, { lazy: true, graphqlEndpoint : "aml:hasura:api://v1/graphql" });
  //const apiUserPermissions = useQuery(query_user_permission, {}, { lazy: true, graphqlEndpoint : "aml:hasura:api://v1/graphql" });
  const apiAutovalutazioni = useQuery(
    query_autoValutazione_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUserById = useQuery(
    query_user_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteAutovalutazione = useMutation(
    mutation_delete_autovalutazione,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api

  const [isTenantEnabled, setIsTenantEnabled] = useState(false);
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaAutovalutazione,
      false,
      false,
      false,
      false
    )
  );

  const [dataItems, setDataItems] = useState([]); //Contiene i records
  const [dataColumns, setDataColumns] = useState([]); //Contiene le columns
  const [dataRows, setDataRows] = useState([]); //Contiene le rows

  const [showDeleteModal, setShowDeleteModal] = useState(false); //Modal per la conferma di eliminazione di un record
  const [idToPerform, setIdToPerform] = useState(0); //Contiene l'id del record da eliminare

  useEffect(() => {
    loadDatas();
  }, [isDataLoaded]);
  async function loadDatas() {
    try {
      LicenseInfo.setLicenseKey(
        "81c5b74554e34c1f397e9cedbf341f5dTz05Mzc1MyxFPTE3NTE3OTM2MzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
      );

      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathAutoValutazioneHome,
            entityId: "",
            entityName: "",
          });

          const tenantInfos = sharedDatas.getPropertyByName("tenantModel");
          const isEnabled =
            !tenantInfos.isReadOnly &&
            !tenantInfos.isDisabled &&
            !tenantInfos.isDeleted;
          setIsTenantEnabled(isEnabled);

          /**************************************/
          //Carico i permessi relativi all'utente
          const userPerms = sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaAutovalutazione)[0];
          setUserPermissions(userPerms);

          /**************************************/
          //Carico le Autovalutazioni dei rischi
          setDataColumns([
            { field: "id", headerName: "Id", type: "number", flex: 0.2 },
            {
              field: "date",
              headerName: t("autovalutazione.form.data"),
              type: "date",
              flex: 0.2,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "riTotale",
              headerName: t("autovalutazione.form.rischioInerente"),
              type: "number",
              flex: 0.2,
              editable: false,
            },
            {
              field: "lvTotale",
              headerName: t("autovalutazione.form.livelloVulnerabilita"),
              type: "number",
              flex: 0.2,
              editable: false,
            },
            {
              field: "rrTotale",
              headerName: t("autovalutazione.form.rischioResiduo"),
              type: "number",
              flex: 0.2,
              editable: false,
            },
            {
              field: "livelloRischio",
              headerName: t("autovalutazione.form.livelloRischio"),
              type: "string",
              flex: 0.2,
              renderCell: (params) => renderLivelloRischio(params.row),
              valueGetter: (params) => renderLivelloRischioS(params.row),
            },
            {
              field: "providedBy",
              headerName: "Elaborata da",
              type: "string",
              flex: 0.3,
              editable: false,
            },
            {
              field: "createdOn",
              headerName: t("fascicolo.form.creatoIl"),
              type: "dateTime",
              flex: 0.2,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "modifiedOn",
              headerName: t("fascicolo.form.modificatoIl"),
              type: "dateTime",
              flex: 0.2,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.2,
              filterable: false,
              renderCell: (params) =>
                renderDetailsButton(isEnabled, userPerms, params.row),
            },
          ]);

          const autoValResponse = await apiAutovalutazioni.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
          });
          const arrItems = [];
          for (
            let i = 0;
            i < autoValResponse.data.data.autovalutazione.length;
            i++
          ) {
            const item = autoValResponse.data.data.autovalutazione[i];
            //const user = await apiUserById.fetch({ userId: item.ProvidedBy, tenantId: tenantId });
            const userResponse = await apiUserById.fetch({
              userId: item.ProvidedBy,
            });
            const result = userResponse.data.data.user;

            let name = "";
            let surname = "";
            if (result.length > 0) {
              const user = result[0];
              const tenantsArr = user.user_tenants;
              if (
                tenantsArr.filter((x) => x.TenantId === tenantId).length > 0
              ) {
                name = user.Name;
                surname = user.Surname;
              }
            }

            const itemModel = new autoValutazioneModel(
              item.Id,
              item.TenantId,
              item.AziendaId,
              item.TenantTipoId,
              item.Date,
              item.Ri1,
              item.Ri2,
              item.Ri3,
              item.Ri4,
              item.Lv1,
              item.Lv2,
              item.Lv3,
              item.Lv4,
              item.RiTotale,
              item.LvTotale,
              item.RrTotale,
              item.LivelloRischio,
              name + " " + surname,
              item.Note,
              item.CreatedOn,
              item.ModifiedOn,
              item.CreatedBy,
              item.ModifiedBy
            );

            arrItems.push(itemModel);
          }
          setDataItems(arrItems);
          setDataRows(arrItems);
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AutovalutazioneHome",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //Add New Button
  const handleAddNew = () => {
    setLocalStorageSelectedAutovalutazione(0);
    navigate(pathAutoValutazioneAdd);
  };

  //Grid Button Detail
  const renderLivelloRischio = (item) => {
    let livelloS = t("autovalutazione.form.livelloRischio1");
    let livelloC = chipGreen;

    switch (item.livelloRischio) {
      case 1: {
        livelloS = t("autovalutazione.form.livelloRischio1");
        livelloC = chipGreen;
        break;
      }
      case 2: {
        livelloS = t("autovalutazione.form.livelloRischio2");
        livelloC = chipYellow;
        break;
      }
      case 3: {
        livelloS = t("autovalutazione.form.livelloRischio3");
        livelloC = chipOrange;
        break;
      }
      case 4: {
        livelloS = t("autovalutazione.form.livelloRischio4");
        livelloC = chipRed;
        break;
      }
      default: {
        // livelloS = t("autovalutazione.form.livelloRischio1");
        // livelloC = chipGreen;
        break;
      }
    }

    return <VaporTag variant="standard" label={livelloS} sx={livelloC} />;
  };
  const renderLivelloRischioS = (item) => {
    let livelloS = t("autovalutazione.form.livelloRischio1");

    switch (item.livelloRischio) {
      case 1: {
        livelloS = t("autovalutazione.form.livelloRischio1");
        break;
      }
      case 2: {
        livelloS = t("autovalutazione.form.livelloRischio2");
        break;
      }
      case 3: {
        livelloS = t("autovalutazione.form.livelloRischio3");
        break;
      }
      case 4: {
        livelloS = t("autovalutazione.form.livelloRischio4");
        break;
      }
      default: {
        // livelloS = t("autovalutazione.form.livelloRischio1");
        break;
      }
    }

    return livelloS;
  };
  const renderDetailsButton = (isEnabled, userPerms, item) => {
    return (
      <Stack direction="row" spacing={0}>
        {isEnabled && userPerms.canDelete ? (
          <IconButton
            color="error"
            onClick={(e) => handleGridBtnDeleteClick(e, item)}
          >
            <DeleteIcon />
          </IconButton>
        ) : null}

        {userPerms.canRead ? (
          <IconButton
            color="primary"
            onClick={(e) => handleGridBtnDetailClick(e, item)}
          >
            <DetailIcon />
          </IconButton>
        ) : null}
      </Stack>
    );
  };
  const handleGridBtnDetailClick = (event, item) => {
    event.stopPropagation();
    setLocalStorageSelectedAutovalutazione(item.id);
    navigate(pathAutoValutazioneAdd);
  };
  const handleGridBtnDeleteClick = (event, item) => {
    event.stopPropagation();
    setIdToPerform(item.id);
    setShowDeleteModal(true);
  };

  //Modal Delete
  const handleModalDeleteClose = () => {
    setIdToPerform(0);
    setShowDeleteModal(false);
  };
  const handleModalDeleteOkClick = async () => {
    try {
      setBackDropOpen(true);

      if (idToPerform > 0) {
        const response = await apiDeleteAutovalutazione.fetch({
          id: parseInt(idToPerform),
        });
        if (response.data.errors !== undefined) {
          enqueueSnackbar(t("error.generale"), { variant: "error" });
        } else {
          //Aggiungo la Audit per tracciare l'azione
          const itemById = JSON.stringify(
            dataItems.filter((item) => item.id === parseInt(idToPerform))[0]
          );
          apiAddAudit.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            area: bsaAutovalutazione,
            operation: "Delete",
            entityType: "autovalutazione",
            entityId: idToPerform.toString(),
            oldValues: itemById,
            newValues: "",
          });

          //Resetto le variabili
          setIdToPerform(0);
          setShowDeleteModal(false);
          setIsDataLoaded(false);
          //loadDatas();
          enqueueSnackbar(t("message.success"), { variant: "success" });
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AutovalutazioneHome",
        method: "handleModalDeleteOkClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };

  return (
    <VaporPage
      title={t("nav.autovalutazioni")}
      headerRight={
        <Button
          variant="text"
          size="medium"
          endIcon={<AddIcon />}
          color="primary"
          onClick={handleAddNew}
          disabled={!isTenantEnabled || !userPermissions.canCreate}
          sx={{ mr: 2 }}
        >
          {t("actions.nuova")}
        </Button>
      }
    >
      <Loading open={backDropOpen} />

      {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

      {showDeleteModal && idToPerform > 0 ? (
        <ModalDelete
          show={showDeleteModal}
          onClose={handleModalDeleteClose}
          onOk={handleModalDeleteOkClick}
        ></ModalDelete>
      ) : null}

      {isDataLoaded && userPermissions.canRead ? (
        <VaporPage.Section>
          <Grid container>
            <Grid item xs={12} sx={{ width: "100%" }}>
              {dataRows.length > 0 ? (
                <DataGridPro
                  rows={dataRows}
                  columns={dataColumns}
                  autoHeight={true}
                  slots={{ toolbar: GridToolbar }}
                  pageSizeOptions={[25, 50, 100]}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  pagination
                  disableRowSelectionOnClick
                  onRowClick={(item, e) =>
                    handleGridBtnDetailClick(e, item.row)
                  }
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  slotProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                  }}
                />
              ) : (
                <NoData />
              )}
            </Grid>
          </Grid>
        </VaporPage.Section>
      ) : null}
    </VaporPage>
  );
};
