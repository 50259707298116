import React, { useState, useEffect } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useAuth,
  useQuery,
  useMutation,
  usePost,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporTag from "@vapor/react-custom/VaporTag";

//Vapor Components

import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import IconButton from "@vapor/react-material/IconButton";

import { ExtendedTabs, ExtendedTab } from "@vapor/react-extended";

import {
  LicenseInfo,
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Icons
import {
  ArrowForward as DetailIcon,
  ArrowForward as ArrowForwardIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";

//Custom styles
import {
  chipGreen,
  chipYellow,
  chipOrange,
  chipRed,
} from "../../businessLogic/styles";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";
import { NoData } from "../../components/NoData";

//Constants, Api and Data Models
import {
  bsaAnagrafica,
  pathAnagraficaHome,
  pathHome,
} from "../../businessLogic/constants";
import {
  setLocalStorageSelectedFascicolo,
  setLocalStorageSelectedEntity,
} from "../../businessLogic/bl";
import {
  userPermissionModel,
  anagraficaModel,
  basePaeseModel,
  baseProvinciaModel,
} from "../../businessLogic/models";
import {
  query_anagrafiche_byTenant,
  query_base_paese,
  query_base_provincia,
} from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";

export const AnagraficaHome = ({ basePath }) => {
  const navigate = useNavigate();
  if (
    sharedDatas.getPropertyByName("userId") === "" ||
    sharedDatas.getPropertyByName("tenantId") === ""
  ) {
    navigate(pathHome);
  }

  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  //Query
  const apiBasePaese = useQuery(
    query_base_paese,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseProvincia = useQuery(
    query_base_provincia,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficheByTenant = useQuery(
    query_anagrafiche_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });

  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api

  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaAnagrafica,
      false,
      false,
      false,
      false
    )
  ); //Contiene i permessi utente
  const [anagraficheList, setAnagraficheList] = useState([]); //Contiene le anagrafiche

  //DataGrid
  const [dataItems, setDataItems] = useState([]); //Contiene i records
  const [dataColumns, setDataColumns] = useState([]); //Contiene le columns
  const [dataRows, setDataRows] = useState([]); //Contiene le rows
  // const [customRowItems, setCustomRowItems] = useState([]);
  // const [actionsRowItems, setActionsRowItems] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  // const [filteredValues, setFilteredValues] = useState([]);
  // const [openAnagDetail, setOpenAnagDetail] = useState(0);
  // const handleOpenAnagDetail = (id) => {
  //     openAnagDetail === id ? setOpenAnagDetail(0) : setOpenAnagDetail(id);
  // };
  // const handleFilterTable = (event, columnId) => {
  //     try {
  //         const filteredValue = event.target.value;
  //         let filtRows = Object.assign([], filteredValue === "" ? dataRows : filteredRows);
  //         let filtValues = Object.assign([], filteredValues);

  //         if (filteredValue !== "") {
  //             filtRows = filtRows.filter(x => x[columnId].toLowerCase().includes(filteredValue.toLowerCase()));

  //             if (filtValues.filter(x => x.column === columnId).length > 0) {
  //                 const item = filteredValues.filter(x => x.column === columnId)[0];
  //                 item.value = filteredValue;
  //             }
  //             else {
  //                 filtValues.push({ column: columnId, value: filteredValue });
  //             }
  //         }
  //         else {
  //             filtValues = filteredValues.filter(x => x.column !== columnId);
  //         }

  //         setFilteredRows(filtRows);
  //         setFilteredValues(filtValues);
  //     }
  //     catch (e) {
  //     }
  // };

  const [dataItems2, setDataItems2] = useState([]); //Contiene i records
  const [dataColumns2, setDataColumns2] = useState([]); //Contiene le columns della DataGrid
  const [dataRows2, setDataRows2] = useState([]); //Contiene le rows della DataGrid

  //TS Digital Api
  const [accessToken, setAccesToken] = useState(useAuth().token);
  const tsApiWorkspace = usePost(
    "workspaceReadApi:///search?view=BASE&page=0&size=10000",
    { lazy: true }
  );

  useEffect(() => {
    loadDatas();
  }, [isDataLoaded]);
  async function loadDatas() {
    try {
      LicenseInfo.setLicenseKey(
        "81c5b74554e34c1f397e9cedbf341f5dTz05Mzc1MyxFPTE3NTE3OTM2MzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
      );

      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded && loggedUserId !== "" && tenantId !== "") {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathAnagraficaHome,
            entityId: "",
            entityName: "",
          });

          /**************************************/
          //Carico i permessi relativi all'utente
          const userPerms = sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaAnagrafica)[0];
          setUserPermissions(userPerms);

          //Recupero le countries
          const tbPaeseResponse = await apiBasePaese.fetch();
          let arrPaeseItems = [];
          tbPaeseResponse.data.data.base_paese.map((item) =>
            arrPaeseItems.push(
              new basePaeseModel(
                item.Id,
                item.Nome,
                item.Identificativo,
                item.IsRischio
              )
            )
          );

          //Recupero le province
          const tbProvinceResponse = await apiBaseProvincia.fetch();
          let arrProvinceItems = [];
          tbProvinceResponse.data.data.base_provincia.map((item) =>
            arrProvinceItems.push(
              new baseProvinciaModel(
                item.Id,
                item.Nome,
                item.Sigla,
                item.Rischio
              )
            )
          );

          /**************************************/
          //Carico le anagrafiche
          setDataColumns([
            { field: "id", headerName: "Id", flex: 0.2, type: "number" },
            {
              field: "cf",
              headerName: "Codice Fiscale",
              flex: 0.3,
              editable: false,
            },
            { field: "pIva", headerName: "P. Iva", flex: 0.3, editable: false },
            // {
            //     field: "denominazione", headerName: "Denominazione anagrafica", flex: 1, editable: false
            // },
            {
              field: "identificato",
              headerName: "Identificato",
              type: "boolean",
              flex: 0.3,
              editable: false,
            },
            { field: "ppe", headerName: "PPE", flex: 0.5, editable: false },
            {
              field: "rischioAreaGeo",
              headerName: "Rischio area geografica anagrafica",
              flex: 0.5,
              editable: false,
              renderCell: (params) => renderLivelloRischio(params.row),
              valueGetter: (params) => renderLivelloRischioS(params.row),
            },
            //{ field: "fascicolo", headerName: "Fascicolo", flex: 0.5, editable: false, },
            { field: "ruolo", headerName: "Ruolo", flex: 0.4, editable: false },
            {
              field: "data",
              headerName: "Data",
              type: "date",
              flex: 0.3,
              editable: false,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.2,
              filterable: false,
              renderCell: (params) =>
                renderDetailsButton(userPerms, params.row),
            },
          ]);
          const anagResponse = await apiAnagraficheByTenant.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          const arrItems = [];
          const arrCf = [];

          for (let i = 0; i < anagResponse.data.data.anagrafica.length; i++) {
            let item = anagResponse.data.data.anagrafica[i];

            if (item.anagrafica_fascicolos.length > 0) {
              //Recupero l'ultima versione
              const lastVersion = anagResponse.data.data.anagrafica.filter(
                (x) => x.Cf === item.Cf && x.IsPg === item.IsPg
              );
              lastVersion.sort((a, b) => b.versionN - a.versionN); //ordino per versionN desc
              item = lastVersion[0];

              if (arrItems.filter((x) => x.id === item.Id).length <= 0) {
                const fascicoliArr = [];

                for (let m = 0; m < item.anagrafica_fascicolos.length; m++) {
                  const fascicoloItem = item.anagrafica_fascicolos[m].fascicolo;

                  if (
                    fascicoliArr.filter((x) => x.id === fascicoloItem.Id)
                      .length <= 0
                  ) {
                    const fascicoloModel = {
                      id: fascicoloItem.Id,
                      nome: fascicoloItem.Name,
                      data: fascicoloItem.Date,
                      anagId: item.Id,
                      ruolo:
                        item.anagrafica_fascicolos[m].base_tipo_anagrafica
                          .Value,
                    };
                    fascicoliArr.push(fascicoloModel);
                  }
                }

                const hasIdentificazioni =
                  item.anagrafica_identificaziones.length > 0 ? "Sì" : "No";

                let ppe = "-";
                if (!item.IsPg) {
                  if (item.IsPep) {
                    ppe = "E' una persona politicamente esposta";
                  } else {
                    if (!item.IsNotPepMoreThanYear) {
                      ppe = "Non è una persona politicamente esposta";
                    } else if (item.IsNotPepMoreThanYear) {
                      ppe =
                        "Non è una persona politicamente esposta da più di un anno";
                    }
                  }
                }

                var denominazione = item.Nome + " " + item.Cognome;
                var pIva = "-";
                if (item.IsPg) {
                  denominazione = item.Denominazione;
                  pIva = item.PIva;
                }

                //Rischio area geografica
                let rischioAreaGeo = 1;
                if (item.AddrPaeseId !== null && item.AddrPaeseId > 0) {
                  const country = arrPaeseItems.filter(
                    (x) => x.id === item.AddrPaeseId
                  )[0];
                  if (country.identificativo === "ITA") {
                    if (item.AddrProvinciaId > 0) {
                      const provincia = arrProvinceItems.filter(
                        (x) => x.id === item.AddrProvinciaId
                      )[0];
                      rischioAreaGeo = provincia.rischio;
                    }
                  } else {
                    rischioAreaGeo = 4;
                  }
                }

                // const itemModel = {
                //     "id": item.Id, "cf": item.Cf, "pIva": item.PIva, "denominazione": denominazione,
                //     "identificato": hasIdentificazioni, "ppe": ppe, "rischioAreaGeo": rischioAreaGeo, "fascicoli": fascicoliArr
                // }

                // arrItems.push(itemModel);

                for (let f = 0; f < fascicoliArr.length; f++) {
                  const itemModel = {
                    path: [
                      denominazione,
                      fascicoliArr[f].nome,
                      fascicoliArr[f].ruolo,
                    ],
                    pathId: uuidv4(),
                    id: item.Id,
                    cf: item.Cf,
                    pIva: item.PIva,
                    denominazione: denominazione,
                    identificato: hasIdentificazioni,
                    ppe: ppe,
                    rischioAreaGeo: rischioAreaGeo,
                    fascicoloId: fascicoliArr[f].id,
                    fascicolo: fascicoliArr[f].nome,
                    ruolo: fascicoliArr[f].ruolo,
                    data: fascicoliArr[f].data,
                  };
                  arrItems.push(itemModel);
                }
              }
            }
          }

          setAnagraficheList(arrItems);
          setDataItems(arrItems);
          setDataRows(arrItems);
          setFilteredRows(arrItems);
          /**************************************/
          //Carico le anagrafiche da Workspace
          try {
            setDataColumns2([
              { field: "id", headerName: "Id", flex: 0.2 },
              {
                field: "cf",
                headerName: "Codice Fiscale",
                flex: 0.3,
                editable: false,
              },
              {
                field: "pIva",
                headerName: "P. Iva",
                flex: 0.3,
                editable: false,
              },
              {
                field: "denominazione",
                headerName: "Denominazione",
                flex: 1,
                editable: false,
              },
              { field: "nome", headerName: "Nome", flex: 0.5, editable: false },
              {
                field: "cognome",
                headerName: "Cognome",
                flex: 0.5,
                editable: false,
              },
              { field: "actions", headerName: "", flex: 0.2 },
            ]);

            const anagWSResponse = await tsApiWorkspace.fetch({
              headers: {
                Authorization: "Bearer " + accessToken,
                "X-App-Name": "TS420",
                "X-App-Version": "1.0.0",
                "X-Request-ID": uuidv4(),
                "X-Correlation-ID": uuidv4(),
                "Content-Type": "application/json",
                "X-Item-ID": tenantId,
                "X-User-ID": loggedUserId,
              },
              data: {
                ownerId: tenantId,
                status: "ACTIVE",
              },
            });
            const tenantModel = sharedDatas.getPropertyByName("tenantModel");

            const arrItems2 = [];
            var counter = 1000000; //Setto un id Int per l'anagrafica
            for (let i = 0; i < anagWSResponse.data.content.length; i++) {
              const anagrafica = anagWSResponse.data.content[i];
              //if (arrItems.filter(x => x.cf === anagrafica.personalData.taxIdentifier).length <= 0 && arrItems.filter(x => x.cf === tenantModel.vatNumber).length <= 0) {
              if (
                arrItems.filter(
                  (x) => x.cf === anagrafica.personalData.taxIdentifier
                ).length <= 0 &&
                anagrafica.personalData.taxIdentifier !== tenantModel.vatNumber
              ) {
                const anagAv = new anagraficaModel(
                  counter,
                  0,
                  anagrafica.classifier === "COMPANY" ? true : false,
                  anagrafica.personalData.taxIdentifier,
                  anagrafica.personalData.name,
                  anagrafica.personalData.surname,
                  0,
                  anagrafica.personalData.birthplace,
                  anagrafica.personalData.birthDate,
                  "",
                  anagrafica.personalData.businessName,
                  anagrafica.personalData.vatIdentifier,
                  "",
                  "",
                  0,
                  0,
                  "",
                  0,
                  "",
                  "",
                  "",
                  "",
                  0,
                  0,
                  "",
                  0,
                  "",
                  "",
                  "",
                  "",
                  false,
                  false,
                  1,
                  new Date(),
                  false,
                  [],
                  [],
                  "",
                  loggedUserId
                );

                arrItems2.push(anagAv);
                counter = counter + 1;
              }
            }
            setDataItems2(arrItems2);
            setDataRows2(arrItems2);
          } catch (e) {
            apiAddLog.fetch({
              tenantId: sharedDatas.getPropertyByName("tenantId"),
              aziendaId: sharedDatas.getPropertyByName("aziendaId"),
              level: "ERROR",
              message: e.message,
              stack: e.stack,
              area: "AnagraficaHome",
              method: "loadDatas",
              version: sharedDatas.getPropertyByName("version"),
            });
          }
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaHome",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //Tabs
  const [selectedTab, setSelectedTab] = useState("tabAnagrafiche");
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  //DataGrid
  //Grid Button Detail
  const renderLivelloRischio = (item) => {
    if (item.rischioAreaGeo !== undefined) {
      let livelloS = t("valutazione.form.rischioInerenteLivello1");
      let livelloC = chipGreen;

      switch (item.rischioAreaGeo) {
        case 1: {
          livelloS = t("valutazione.form.rischioInerenteLivello1");
          livelloC = chipGreen;
          break;
        }
        case 2: {
          livelloS = t("valutazione.form.rischioInerenteLivello2");
          livelloC = chipYellow;
          break;
        }
        case 3: {
          livelloS = t("valutazione.form.rischioInerenteLivello3");
          livelloC = chipOrange;
          break;
        }
        case 4: {
          livelloS = t("valutazione.form.rischioInerenteLivello4");
          livelloC = chipRed;
          break;
        }
        default: {
          // livelloS = t("valutazione.form.rischioInerenteLivello1");
          // livelloC = chipGreen;
          break;
        }
      }

      return <VaporTag variant="standard" label={livelloS} sx={livelloC} />;
    } else {
      return null;
    }
  };
  const renderLivelloRischioS = (item) => {
    if (item.rischioAreaGeo !== undefined) {
      let livelloS = t("valutazione.form.rischioInerenteLivello1");

      switch (item.rischioAreaGeo) {
        case 1: {
          livelloS = t("valutazione.form.rischioInerenteLivello1");
          break;
        }
        case 2: {
          livelloS = t("valutazione.form.rischioInerenteLivello2");
          break;
        }
        case 3: {
          livelloS = t("valutazione.form.rischioInerenteLivello3");
          break;
        }
        case 4: {
          livelloS = t("valutazione.form.rischioInerenteLivello4");
          break;
        }
        default: {
          //livelloS = t("valutazione.form.rischioInerenteLivello1");
          break;
        }
      }

      return livelloS;
    } else {
      return null;
    }
  };
  const renderDetailsButton = (userPerms, item) => {
    if (item.id !== undefined) {
      return (
        <Stack direction="row" spacing={0}>
          {userPerms.canRead ? (
            <IconButton
              color="primary"
              onClick={(e) => handleGridBtnDetailClick(e, item)}
            >
              <DetailIcon />
            </IconButton>
          ) : null}
        </Stack>
      );
    } else {
      return null;
    }
  };
  const handleGridBtnDetailClick = (event, item) => {
    if (item.fascicoloId !== undefined && item.id !== undefined) {
      event.stopPropagation();
      setLocalStorageSelectedFascicolo(item.fascicoloId);
      setLocalStorageSelectedEntity("anagrafica", item.id);
      navigate("/fascicolo/manage");
    }
  };

  return (
    <VaporPage title={t("nav.anagrafiche")}>
      <Loading open={backDropOpen} />

      {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

      {isDataLoaded && userPermissions.canRead ? (
        <VaporPage.Section>
          <ExtendedTabs
            variant="standard"
            size="small"
            value={selectedTab}
            onChange={handleChangeTab}
            sx={{ mb: 2 }}
          >
            <ExtendedTab
              value="tabAnagrafiche"
              label={t("anagrafica.anagraficaForm.label1")}
            />
            <ExtendedTab
              value="tabDisponibili"
              label={t("anagrafica.anagraficaForm.label2")}
            />
          </ExtendedTabs>

          {selectedTab === "tabAnagrafiche" ? (
            <Grid container>
              <Grid item xs={12} sx={{ width: "100%" }}>
                {isDataLoaded && dataRows.length > 0 ? (
                  <DataGridPro
                    rows={dataRows}
                    columns={dataColumns}
                    getRowId={(row) => row.pathId}
                    autoHeight={true}
                    slots={{ toolbar: GridToolbar }}
                    pageSizeOptions={[25, 50, 100]}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    pagination
                    disableRowSelectionOnClick
                    treeData
                    getTreeDataPath={(row) => row.path}
                    onRowClick={(item, e) =>
                      handleGridBtnDetailClick(e, item.row)
                    }
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                        },
                      },
                      // rowGrouping: {
                      //     model: ['denominazione'],
                      // },
                      pagination: {
                        paginationModel: {
                          pageSize: 25,
                        },
                      },
                    }}
                    groupingColDef={(params) => {
                      return {
                        headerName: "Denominazione",
                        flex: 1,
                        filterable: true,
                        sortable: true,
                        pinnable: true,
                        disableColumnMenu: false,
                        hideDescendantCount: true,
                      };
                    }}
                    slotProps={{
                      toolbar: { printOptions: { disableToolbarButton: true } },
                    }}
                  />
                ) : (
                  <NoData size="small" />
                )}
              </Grid>
            </Grid>
          ) : null}

          {selectedTab === "tabDisponibili" ? (
            <Grid container>
              <Grid item xs={12} sx={{ width: "100%" }}>
                {dataRows2.length > 0 ? (
                  // <TableData tableSize="medium" columns={dataColumns2} rows={dataRows2} customRowItems={null} actions={null}></TableData>
                  <DataGridPro
                    rows={dataRows2}
                    columns={dataColumns2}
                    autoHeight={true}
                    slots={{ toolbar: GridToolbar }}
                    pageSizeOptions={[25, 50, 100]}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    pagination
                    disableRowSelectionOnClick
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                        },
                      },
                      pagination: {
                        paginationModel: {
                          pageSize: 25,
                        },
                      },
                    }}
                    slotProps={{
                      toolbar: { printOptions: { disableToolbarButton: true } },
                    }}
                  />
                ) : (
                  <NoData />
                )}
              </Grid>
            </Grid>
          ) : null}
        </VaporPage.Section>
      ) : null}
    </VaporPage>
  );
};
